import Features from "@/components/Features";
import Hero from "@/components/Hero";
import Testimonials from "@/components/Testimonials";
import About from "../About";
import Banner from "../Banner";
import Gallery from "../Gallery";
import Footer from "../Footer";

function LandingPageApp() {
  return (
    <div className="main-container mx-auto">
      <Hero />
      <Features />
      <Testimonials />
      <About />
      <Gallery />
      <Banner />
      <Footer withCopyright={true} />
    </div>
  );
}

export default LandingPageApp;
