import {Offcanvas,OffcanvasHeader,OffcanvasBody} from 'reactstrap'
import { TutorialsContext } from '../TutorialsApp/context';
import { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tutorialsConfig } from "../TutorialsApp/consts";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const TutorialsSidebarOffCanvas = () => {
  const {
    offCanvasIsOpen,
    setOffCanvasIsOpen
  } = useContext(TutorialsContext);
  let { tutorialName } = useParams();

  const toggle = () => {
    setOffCanvasIsOpen(prevState=>!prevState)

  }
  return (
    <Offcanvas toggle={toggle} isOpen={offCanvasIsOpen}>
    <OffcanvasHeader toggle={toggle} className='text-white bg-primary'>
    <FontAwesomeIcon icon={faList} />
        <span className="fs-5 fw-semibold mx-2"> Embroidery tutorials</span>
    </OffcanvasHeader>
    <OffcanvasBody className='p-0'>
    <div
      className="d-flex flex-column align-items-stretch flex-shrink-0 bg-primary view-container h-100"
    >
      <div className="list-group list-group-flush border-bottom scrollarea">
        {tutorialsConfig.map((tutorialProps) => (
          <Link
          onClick={toggle}
            to={`${tutorialProps.name}`}
            className={`list-group-item list-group-item-action bg-secondary py-3 lh-sm ${tutorialName === tutorialProps.name ? 'active': ''}`}
            aria-current="true"
          >
            <div className="d-flex w-100 align-items-center justify-content-between">
              <strong className="mb-1">{tutorialProps.title}</strong>
            </div>
            <div className="col-10 mb-1 small">{tutorialProps.text}</div>
          </Link>
        ))}
      </div>
    </div>
    </OffcanvasBody>
  </Offcanvas>
  );
};

export default TutorialsSidebarOffCanvas;
