import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faListOl,
  faPalette
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Features = () => {
  const features = [
    {
      title: "Learn at your own pace",
      text: "Discover embroidery skills at your rhythm",
      icon: faClock,
    },
    {
      title: "Step-by-step guidance",
      text: "From beginner to embroidery pro.",
      icon: faListOl,
    },
    {
      title: "Make sweaters uiniquely yours",
      text: "Craft your signature style.",
      icon: faPalette,
    },
  ];

  return (
    <div className="mt-5 pt-5 text-center" style={{maxWidth: '100%', overflow: 'hidden'}}>
      <h2 className="mb-5 kelvinch primary fs-1">Unlock your embroidery potential</h2>
      <div className="container-md">
      <p className="mx-auto w-sm-50" >Dive into our captivating video tutorials and master the art of embroidering names, charming floral initials and delightful designs like daisies, suns, trees, rainbows, mountains, butterflies, basketballs and much more on your sweaters!</p>

      </div>
      <Row className="grid-container" xs={1} sm={2} lg={3}>
        {features.map(({ title, text, icon }) => (
          <Col className="px-5 py-4 px-md-5 py-md-4 ">
            <FontAwesomeIcon
              icon={icon}
              size="2xl"
              className="color-primary mb-4"
            />
            <h3 className='fs-4'>{title}</h3>
            <p>{text}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Features;
