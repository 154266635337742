export const tutorialsConfig = [
    {
      name: "butterfly",
      title: "🦋 Butterfly embroidery mastery",
      text: "Explore the art of creating vibrant butterflies.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/76ddbde53c20f1d79bdf0208106815ff/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F76ddbde53c20f1d79bdf0208106815ff%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
    },
    {
      name: "bumblebee",
      title: "🐝 Bumblebee buzz",
      text: "Bring bumblebees to life with vibrant stripes and translucent wing details.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/0dcda953e517b76172a9f3c76ec3ab0f/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F0dcda953e517b76172a9f3c76ec3ab0f%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "dinosaur",
      title: "🦕 Dinosaur embroidery adventure",
      text: "Dive into the world of prehistoric embroidery with unique dinosaur designs.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/effd1ebedbedcff0ee0bc387905319b9/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Feffd1ebedbedcff0ee0bc387905319b9%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
    },
    {
      name: "dinosaur2",
      title: "🦕 Dino discovery",
      text: "Dive deeper into the prehistoric with a second dinosaur design.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/e4909815fab290d073d937a36901ee54/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fe4909815fab290d073d937a36901ee54%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "giraffe",
      title: "🦒 Giraffe bliss",
      text: "Embroider a sweet and happy giraffe, portraying its gentle and joyful nature in a charming design.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/1c02385ebcb5b45275402f0612ce4e7c/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F1c02385ebcb5b45275402f0612ce4e7c%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },   
    {
      name: "ladybird",
      title: "🐞 Ladybird charm",
      text: "Create a cute ladybird, emphasizing its delicate spots for a touch of nature.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/d68ac09d9547c051fc3d60818d79e31e/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fd68ac09d9547c051fc3d60818d79e31e%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "llama",
      title: "🦙 Llama leisure",
      text: "Stitch a whimsical llama, perfect for a fun and playful design.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/670fe990251bea0623fc2871cbf1b826/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F670fe990251bea0623fc2871cbf1b826%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "mountains",
      title: "🏔️ Mountains scenery embroidery",
      text: "Capture the majestic beauty of mountains in your embroidery work.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/d48bcfd31705f57742b591f6749fc23d/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fd48bcfd31705f57742b591f6749fc23d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "rainbow",
      title: "🌈 Rainbow colors",
      text: "Add a spectrum of color to your projects with vibrant rainbow.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/8f62a2d34931baa5bf59bfa932165977/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F8f62a2d34931baa5bf59bfa932165977%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "sun",
      title: "☀️ Sunny embroidery",
      text: "Learn how to bring warmth and cheer with sunny patterns.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/d3cf8dcd897cc6f2257c4797dd4ec03f/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fd3cf8dcd897cc6f2257c4797dd4ec03f%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "baloon",
      title: "🎈 Balloon journeys",
      text: "Craft the gentle curves and colorful patterns of soaring hot air balloons.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/8c830a2bf8dbd4dc050e9df1e5a1992f/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F8c830a2bf8dbd4dc050e9df1e5a1992f%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "airplane",
      title: "🛩️ Skyward flight",
      text: "Craft a classic airplane in flight, capturing its sleek design and the sense of adventure in the skies.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/f6c7917fcc32db27d4b7e274db0466b8/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Ff6c7917fcc32db27d4b7e274db0466b8%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "car",
      title: "🚗 Vintage voyage",
      text: "Stitch a charming vintage car, perfect for a nostalgic touch.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/7ca9b694cab4f9dd904e513c1d263397/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F7ca9b694cab4f9dd904e513c1d263397%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "strawberry",
      title: "🍓 Sweet strawberry designs",
      text: "Create charming, juicy strawberry patterns for a sweet touch.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/a1e3e1d7bf9d72377b3d03826ce3472a/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fa1e3e1d7bf9d72377b3d03826ce3472a%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "leaf",
      title: "🍃 Leaf serenity",
      text: "Embrace the natural beauty of a leaf, capturing its detailed texture and form.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/bf9188a3edbe35f6d58410c75c817ee6/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fbf9188a3edbe35f6d58410c75c817ee6%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "treee",
      title: "🌲 Tree embroidery serenity",
      text: "Discover the peace and tranquility of creating serene tree designs.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/3bd1a5adfc50a8074cfa7d7b81dc0c0f/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F3bd1a5adfc50a8074cfa7d7b81dc0c0f%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "daisy",
      title: "🌼 Daisy pattern perfection",
      text: "Master the art of daisy patterns for a fresh, floral touch.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/28e4b5abbc610e56dd061d9fed195e4a/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F28e4b5abbc610e56dd061d9fed195e4a%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "greenery",
      title: "🌿 Adding greenery to daisies",
      text: "Enhance your daisy designs with touches of lush greenery.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/5e318c7bbfb58d60fe7f8e5ff4997fb0/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F5e318c7bbfb58d60fe7f8e5ff4997fb0%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "aster",
      title: "🌼 Aster bloom mastery",
      text: "Stitch the intricate aster, focusing on radiant petals and lush hues for natural beauty.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/f5b01878dcb25b9d183888e5dd630f6b/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Ff5b01878dcb25b9d183888e5dd630f6b%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "tulip",
      title: "🌷 Tulip elegance",
      text: "Embody the essence of spring with the simple yet striking form of tulips in your stitches.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/865b59d2154267cf561bbfd7a024fab3/iframe?muted=true&poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F865b59d2154267cf561bbfd7a024fab3%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },   
    {
      name: "rose",
      title: "🌹 Velvet rose petals",
      text: "Craft a lush, velvety rose focusing on soft, overlapping textures.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/2e93d211abbc0e529298fac8616c9589/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F2e93d211abbc0e529298fac8616c9589%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "rose2",
      title: "🌹 Whispering rose",
      text: "Capture the delicate beauty of a single rose in bloom, focusing on intricate petal layers.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/339462f713fe978bceb431a6274eb9f7/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F339462f713fe978bceb431a6274eb9f7%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    }, 
    {
      name: "tie-off-stitches",
      title: "🪡 Finishing with flair",
      text: "Learn the art of tying off stitches neatly and securely, ensuring your embroidery projects are as beautiful on the back as they are on the front.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/da74b3b63dbdd91f3706146e9056cfb4/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2Fda74b3b63dbdd91f3706146e9056cfb4%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },
    {
      name: "chain-stitch",
      title: "🔗 Chain stitch mastery",
      text: "Master the versatile chain stitch, learning how to create elegant lines for any project.",
      videoURL: "https://customer-aulz4u443o9cftnz.cloudflarestream.com/75c3186b0de8a709ba65674b6baa0550/iframe?poster=https%3A%2F%2Fcustomer-aulz4u443o9cftnz.cloudflarestream.com%2F75c3186b0de8a709ba65674b6baa0550%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&logo=http%3A%2F%2Fdiscover.moosecozy.com%2Fimages%2Flogo.png",
    },

  ];