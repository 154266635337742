import { Col } from "reactstrap";
import "./styles.scss";

const Hero = () => {
  return (
    <div className='p-sm-5' style={{position: 'relative'}}>
      <div style={{position: 'absolute', left:0, right:0, top:0, height:'100%', zIndex:'-999'}}>
      <img
          style={{objectFit:'cover', objectPosition:'right bottom'}}
          src="/images/hero/w_1600.png"
          srcSet={`/images/hero/w_660.webp 660w, /images/hero/w_973.webp 973w, /images/hero/w_1600.png 1600w`}
          sizes="(max-width: 576px) 660px, (max-width: 992px) 973px, 1600px"
          width="100%"
          height="100%"
          alt="Girl holding a flower in smoked Diana Louise dress"
        />
      </div>
      <Col lg={6} className='hero-overlay mx-auto text-center'>
        <img
            src="/images/logo.png"
            width='130px'
            height='auto'
            alt='moose cozy logo'
            loading='lazy'
          />
        <h1 className='mb-3 mt-2 text-center'>
          <a href='https://www.etsy.com/shop/MooseCozy' className='text-decoration-none'>
            MOOSE COZY
          </a>
        </h1>
        <h3 className='fw-bold'>Discover how to personalize your sweaters </h3>
        <h3 className='fw-bold'>with our exclusive FREE video tutorials!</h3>

        <Col xs={9} sm={7} className='mx-auto'>
          <div class="klaviyo-form-U8jxQi"></div>
        </Col>
      </Col>
    </div>
  );
};

export default Hero;
