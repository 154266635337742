import { tutorialsConfig } from "../TutorialsApp/consts";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';

const TutorialVideo = () => {
  let { tutorialName } = useParams();
  const tutorialConfig = tutorialsConfig.find(config => config.name === tutorialName)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="h-75 pt-4 d-flex flex-column ">
      <h1 className="d-lg-none text-center pb-3">{tutorialConfig.title}</h1>

      <div className={`position-relative h-100 ${isMobile ? 'ratio ratio-1x1' : '' }`} style={{ paddintTop: "177.77777777777777%" }}>
        <iframe
          src={tutorialConfig.videoURL}
          style={{
            border: "none",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
        ></iframe>
      </div>
      <p className="d-lg-none pt-4 text-center">{tutorialConfig.text}</p>

    </div>
  );
};

export default TutorialVideo;
