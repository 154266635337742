import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LandingPageApp from "../LandingPageApp";
import TutorialsApp from "../TutorialsApp";
import TutorialVideo from "../TutorialVideo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPageApp />
  },
  {
    path: "/tutorials",
    element: <TutorialsApp />,
    children: [
      {
        path:':tutorialName',
        element: <TutorialVideo/>
      }
    ]
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
