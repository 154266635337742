import { Col } from "reactstrap";
import "./styles.scss";

const Quote = ({text, name}) => {
  return (
    <div className="text-center my-2">
      <Col className="mx-auto">
        <blockquote className="quote fs-4 text-muted fst-italic">
         {text}
        </blockquote>
        <strong>- {name}</strong>
      </Col>
    </div>
  );
};

export default Quote;
