import { Outlet } from "react-router-dom";
import "./styles.scss";
import TutorialsSidebar from "../TutorialsSidebar";
import TutorialsSidebarOffCanvas from "../TutorialsSidebarOffCanvas";
import { Button } from "reactstrap";

import { TutorialsContext } from "./context";
import { useState } from "react";

const TutorialsApp = () => {
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);
  
  return (
    <TutorialsContext.Provider value={{ offCanvasIsOpen,setOffCanvasIsOpen }}>
      <nav className="navbar navbar-expand-lg border-bottom">
        <div className="container-fluid">
          <a
            className="navbar-brand"
            href="https://www.etsy.com/shop/MooseCozy"
          >
            <img
              src="/images/about.webp"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
              alt="moosecozy logo"
            />
            Moose Cozy
          </a>
          <Button className="navbar-toggler" onClick={()=>{
            setOffCanvasIsOpen(prevState=>!prevState)
          }}>
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
      </nav>
      <div className="d-flex flex-row">
        <TutorialsSidebar />
        <TutorialsSidebarOffCanvas />
        <div className="container-md d-flex flex-column justify-content-center">
          <Outlet />
        </div>
      </div>
    </TutorialsContext.Provider>
  );
};

export default TutorialsApp;
