import { Col, Row } from "reactstrap";
import SocialMedia from "../SocialMedia";

const About = () => {
  return (
    <div className=" my-5 py-5" style={{maxWidth: '100%', overflow: 'hidden'}}>
      <h2 className="pb-5 pt-3 text-center kelvinch primary fs-1">About</h2>

      <Row className="gy-5">
        <Col
          lg={6}
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <img src="/images/about.webp" style={{ maxHeight: "600px", height: "auto", maxWidth: '100%' }} alt="moosecozy logo"/>
        </Col>
        <Col
          lg={6}
          xs={12}
          className="d-flex flex-column justify-content-center align-items-center px-5"
        >
          <p className="fs-5 lh-lg">
          Welcome to Moose Cozy, a brand born from a passion to transform everyday garments into unique, personalized works of art. Specializing in floral initial embroidery and custom name designs on sweaters, my creations are more than just clothing - they're keepsakes infused with love and individuality. 
          </p>
          <p className="fs-5 lh-lg">
          I'm excited to share my skills with you through detailed video tutorials, guiding you step-by-step on how to embroider on sweaters.
          </p>
          <p className="fs-5 lh-lg">
          Join me on this creative journey where I share the calming, joyful art of embroidery. Whether you're crafting a thoughtful gift or adding a personal touch to your wardrobe, I'm here to guide you. With these video tutorials you'll learn the art of sweater embroidery, enabling you to create beautiful, customized pieces for yourself or your loved ones.
          </p>
        </Col>
      </Row>
      <Row>
      <Col
          lg={6}
          xs={12}
          className="d-flex justify-content-center align-items-center px-5 pt-4"
        >
          <SocialMedia />
      </Col>
      <Col
          lg={6}
          xs={12}
          className="d-flex justify-content-center align-items-center px-5"
        >
      </Col>
      </Row>
    </div>
  );
};

export default About;
