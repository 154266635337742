import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./styles.scss";
import { tutorialsConfig } from "../TutorialsApp/consts";
import { useParams } from "react-router-dom";

const TutorialsSidebar = () => {
  let { tutorialName } = useParams();

  return (
    <div
      className="d-none d-lg-flex flex-column align-items-stretch flex-shrink-0 bg-primary view-container"
      style={{ width: "380px" }}
    >
      <div className="text-white d-flex align-items-center flex-shrink-0 p-3 link-body-emphasis text-decoration-none border-bottom">
        <FontAwesomeIcon icon={faList} />
        <span className="fs-5 fw-semibold mx-2"> Embroidery tutorials</span>
      </div>
      <div className="list-group list-group-flush border-bottom scrollarea">
        {tutorialsConfig.map((tutorialProps) => (
          <Link
            to={`${tutorialProps.name}`}
            className={`list-group-item list-group-item-action bg-secondary py-3 lh-sm ${tutorialName === tutorialProps.name ? 'active': ''}`}
            aria-current="true"
          >
            <div className="d-flex w-100 align-items-center justify-content-between">
              <strong className="mb-1">{tutorialProps.title}</strong>
            </div>
            <div className="col-11 mb-1 small">{tutorialProps.text}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TutorialsSidebar;
