import Quote from "../Quote"
import "./styles.scss";
import { Col, Row } from "reactstrap";

const Testimonials = () => {
  const quotes = [
    {
      text: "As someone who's never held a needle before, I was a bit intimidated at first. But these tutorials from Moose Cozy were a game-changer! The instructions are clear, easy to follow and really beginner-friendly. I never thought I'd be able to embroider my own sweaters, but here I am.",
      name: 'Jenna'
    },
    {
      text: "I'm absolutely thrilled with the range of embroidery designs included in these tutorials! From adorable daisies to sun pattern. Kudos to Moose Cozy for such a diversity!",
      name: 'Liam M.'
    },
    {
      text: "I've been looking for a heartfelt gift idea and Moose Cozy's video tutorials were just perfect! I embroidered a sweater for my niece with her name and a little rainbow and she adores it. The tutorials made the whole process enjoyable and fulfilling. Thank you for helping me create something so special! ",
      name: 'Maria'
    },
    {
      text: "Embroidering has become my new favorite hobby, thanks to these fantastic tutorials from Moose Cozy. There's something incredibly soothing about following along and creating something beautiful with my hands. It's my go-to activity after a long day. Plus, seeing the final product is so rewarding. These tutorials are not just educational - they're a form of therapy!",
      name: 'Kate'
    },
    {
      text: "I was on the hunt for embroidery tutorials that catered to my son's interests. The range of boy-friendly designs like basketballs, helmets, trees, cowboy hats, soccer fields and lightning bolts was exactly what I needed. It's so rare to find tutorials that go beyond the usual floral patterns and offer something for boys. My son is thrilled with his personalized sweater featuring a soccer field and I'm already planning to embroider a helmet on his next one. These tutorials are inclusive, creative and a true standout. A big thank you to Moose Cozy for filling this gap!",
      name: 'Anna'
    },
  ]
  return (
    <div className="testimonials-container my-5 py-5 bg-secondary" style={{maxWidth: '100%', overflow: 'hidden'}}>
      <h2 className="pt-3 px-2 text-center kelvinch primary fs-1">Hear from our happy crafters</h2>
      <Row className="grid-container justify-content-center" xs={1} sm={2}>
                {quotes.map(quoteProps=><Col className=" "><Quote {...quoteProps} /></Col>)}
      </Row>
    </div>
  );
};

export default Testimonials;
